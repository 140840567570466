import React, { useState } from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { TwoColumnsLayout } from '../../../common/TwoColumnsLayout'
import { MeetingsList } from './MeetingsList'
import { useParams } from 'react-router-dom'
import { useGetEmployeeMeeting, useGetMeetingNotesFeedback } from '@src/api/meetings'
import { FilterByInterface } from '@src/interfaces/data'
import { GoogleCalendarEventAttendee, MeetingEvent } from '@src/interfaces/meetings'
import { SummaryHeader } from './components/SummaryHeader'
import { useReviewCycleData } from './hooks/useReviewCycleData'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { ActionButton, Token, Widget } from '@revolut/ui-kit'
import { NotesHeader } from './components/NotesHeader'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { MEETING_CALENDAR_LINK } from '@src/constants/externalLinks'

export const OneToOneSummary = () => {
  const [selectedMeeting, setSelectedMeeting] = useState<
    MeetingEvent<GoogleCalendarEventAttendee> | undefined
  >()
  const [selectedReviewCycle, setSelectedReviewCycle] = useState<FilterByInterface[]>([])

  const params = useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const openNewTab = useOpenNewTab()

  const employeeId = Number(params.employeeId)
  const meetingId = Number(params.meetingId)
  const managerId = Number(params.managerId)

  const activeFilterCycleId = selectedReviewCycle[0]?.filters[0]?.id

  const {
    isLoading: isReviewCyclesLoading,
    dateRanges,
    reviewCycleOptions,
  } = useReviewCycleData(activeFilterCycleId, setSelectedReviewCycle)

  const { data: meetingNotesDetails, isLoading: isLoadingMeetingNotesDetails } =
    useGetMeetingNotesFeedback(selectedMeeting?.id)

  const { data, isLoading: isLoadingEmployeeManagerMeeting } = useGetEmployeeMeeting(
    employeeId,
    meetingId,
  )

  const onChangeSchedule = () => {
    openNewTab(MEETING_CALENDAR_LINK)
  }

  const onCycleChange = (cycle: FilterByInterface) => {
    setSelectedReviewCycle([cycle])
  }
  const isNotesEmptyState =
    selectedMeeting && !meetingNotesDetails?.id && !isLoadingMeetingNotesDetails

  return (
    <PageWrapper>
      <SummaryHeader
        data={data}
        isLoading={isLoadingEmployeeManagerMeeting}
        onChangeSchedule={onChangeSchedule}
      />

      <CycleFilterButton
        isLoading={isReviewCyclesLoading}
        columnName=""
        filter={selectedReviewCycle}
        onFilterChange={onCycleChange}
        selector={() => Promise.resolve(reviewCycleOptions)}
      />

      <PageBody mt="s-4" maxWidthMd={Token.breakpoint.max}>
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={270}
          left={
            dateRanges.startDate &&
            dateRanges.endDate && (
              <MeetingsList
                startDate={dateRanges.startDate}
                endDate={dateRanges.endDate}
                isLoading={isReviewCyclesLoading}
                employeeId={employeeId}
                managerId={managerId}
                selectedMeeting={selectedMeeting}
                setSelectedMeeting={setSelectedMeeting}
              />
            )
          }
          right={
            <Widget>
              {isNotesEmptyState ? (
                <EmptyTableRaw
                  title="You don’t have meeting notes yet"
                  imageId="3D298"
                  description="Record talking points and actions, and take personal notes"
                  action={
                    <ActionButton useIcon="Plus">Create meeting notes</ActionButton>
                  }
                />
              ) : (
                <NotesHeader
                  meetingId={selectedMeeting?.id}
                  meetingNotes={meetingNotesDetails}
                  isLoading={isLoadingMeetingNotesDetails}
                />
              )}
            </Widget>
          }
        />
      </PageBody>
    </PageWrapper>
  )
}
